<!-- @Author: Yu_Bo -->
<template>
	<div class='member_edit zc_dialog_box'>
		<el-dialog :visible.sync="dialogVisible" width="534px" :close-on-click-modal='false' @close='cancelBtn'>
		  <div slot="title">
		    <div class="name">{{ title }}</div>
		  </div>
		  <div class="inv_main">
		    <div class="main_text">
		      <div class="left">名称</div>
		      <div class="right">
		        <el-input v-model="name" placeholder="请输入名称" maxlength="10" show-word-limit>
		        </el-input>
		      </div>
		    </div>
		    <div class="main_text">
		      <div class="left"><span>*</span>手机号</div>
		      <div class="right">
		        <el-input :disabled='type == 1 ? false : true' v-model="phone" placeholder="请输入手机号" maxlength="11" show-word-limit>
		        </el-input>
		      </div>
		    </div>
		    <div class="main_text">
		      <div class="left"><span>*</span>角色</div>
		      <div class="right">
		        <el-select v-model="roleValue" placeholder="请选择角色">
		          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
		          </el-option>
		        </el-select>
		      </div>
		    </div>
		  </div>
		  <div slot="footer" style="padding: 0 20px;">
		    <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
		    <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      type: {
        type: Number,
        defaul: 2
      },
      roleList: {
        type: Array,
        defaul: []
      },
    },
		data() {
			return{
        dialogVisible:false,
        //
        id:'',
				name:'',
				phone:'',
				roleValue:'',
        //
        btnLoading:false,
			}
		},
		computed: {
		  title() {
		    return this.type == 1 ? '新增' : '编辑'
		  },
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(info) {
        this.id=info.id
        this.name=info.nickname
        this.phone=info.mobile
        this.roleValue=info.role_ids
        this.dialogVisible = true
      },
      // 取消
      cancelBtn() {
        this.name=''
        this.phone=''
        this.roleValue=''
        this.dialogVisible = false
      },
      // 确定
      affirmBtn(){
        var that = this
        if(that.name==''){
          that.$errMsg('请输入名称')
          return
        }else if(that.roleValue==''){
          that.$errMsg('请选择角色')
          return
        }else{
          // 编辑
          that.btnLoading=true
          var params = {
            nickname:that.name,
            role_ids:that.roleValue,
          }
          that.$purviewApi.putSystemUser(params,that.id).then(res => {
            that.btnLoading=false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.cancelBtn()
              that.$emit('submit')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.member_edit{
		.inv_main {
		  width: 100%;
		  padding: 10px 20px 0 0;

		  .main_text {
		    width: 100%;
		    margin-bottom: 20px;
		    display: flex;
		    justify-content: space-between;

		    .left {
		      width: 90px;
		      line-height: 40px;
		      text-align: right;
		      font-size: 14px;
		      font-family: PingFangSC-Regular, PingFang SC;
		      font-weight: 400;
		      color: #333333;

		      span {
		        color: #E00C25;
		      }
		    }

		    .right {
		      width: 370px;

		      .el-input {
		        width: 100%;
		      }

		      .el-select {
		        width: 100%;
		      }
		      .el-cascader{
		        width: 100%;
		      }
		      .right_txt{
		        padding-top: 10px;
		        font-size: 12px;
		        font-family: PingFangSC-Regular, PingFang SC;
		        font-weight: 400;
		        color: #FF2B2B;
		      }
		    }
		  }
		  .main_text:last-child {
		    margin-bottom: 0;
		  }
		}
	}
</style>
